import Vue from 'vue';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
// 1. 引入你需要的组件
import Vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import queryString from 'query-string';
import * as Sentry from '@sentry/vue';

// https://localhost:3000/?type=1&video=1
const parsed = queryString.parse(location.href.split('?')[1]);
console.log(parsed);
if (parsed['type']) {
    localStorage.setItem('dipai-chat-type', parsed['type']);
} else {
    localStorage.setItem('dipai-chat-type', '1');
}
if (parsed['video']) {
    localStorage.setItem('dipai-chat-video', parsed['video']);
} else {
    localStorage.setItem('dipai-chat-video', '1');
}

import App from './App.vue';
import router from './router';
import store from './store';
import './api/axios';

Sentry.init({
    Vue,
    dsn: 'https://9f288c3ab0a8425195b5d1cc63f66c6a@o278678.ingest.sentry.io/4505228322078720',
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.use(Vant);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('dipai-ai-chat-session-id');
    if (!token && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
});

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');

