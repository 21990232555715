<template>
    <div class="backgroup-music-block"> </div>
</template>

<script>
import { Howl, Howler } from 'howler';
import { mapState } from 'vuex';
export default {
    props: {
        music: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            musicObj: null,
        };
    },
    computed: {
        ...mapState('app', ['actionStatus', 'sysConfig']),
    },
    watch: {
        actionStatus(newVal) {
            if (['ready'].includes(newVal) && !this.musicObj) {
                this.load();
            } else if (['idle'].includes(newVal) && this.musicObj) {
                this.play();
            } else {
                this.pause();
            }
        },
    },
    mounted() {},
    destroyed() {},
    methods: {
        load() {
            if (!this.sysConfig.aichat_background_music_enable_flag) {
                return;
            }
            console.log('load ');
            // Setup the new Howl.
            const sound = new Howl({
                src: [this.music],
                autoplay: true,
                loop: true,
                volume: 0.5,
            });

            // Play the sound.
            sound.play();

            // Change global volume.
            Howler.volume(0.5);

            this.musicObj = sound;
        },
        pause() {
            if (this.musicObj) {
                this.musicObj.pause();
            }
        },
        play() {
            if (this.musicObj) {
                this.musicObj.play();
            }
        },
    },
};
</script>

<style scoped lang="less">
.backgroup-music-block {
    position: absolute;
    width: 0;
    height: 0;
}
</style>

