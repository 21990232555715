<!-- eslint-disable no-undef -->
<template>
    <div id="app">
        <!-- <Main /> -->
        <router-view> </router-view>
        <BackgroundMusic :music="music" />
        <SoundEffect />
    </div>
</template>

<script>
import lifecycle from 'page-lifecycle';
import CryptoJS from 'crypto-js';
import { mapActions } from 'vuex';
import BackgroundMusic from './components/common/BackgroupMusic.vue';
import SoundEffect from './components/common/SoundEffect.vue';

export default {
    name: 'App',
    components: {
        BackgroundMusic,
        SoundEffect,
    },
    data() {
        return {
            music: require('./assets/audio/they-say.mp3'),
        };
    },
    mounted() {
        lifecycle.addEventListener('statechange', this.onLifeCycleStateChange);
        window.addEventListener('contextmenu', e => e.preventDefault());
        navigator.mediaDevices.getUserMedia({ audio: true, video: false });

        // 初始化botApp对象
        const key = '795e38d0-3440-a42d-07a1-52a717d5b655';
        const random1 = '3691308f2a4c2f6983f2880d32e29c84';
        const random2 = 'dc468c70fb574ebd07287b38d0d0676d';

        try {
            const botApp = new window.BotApp({
                random1: random1, // 随机字符串，长度不限，由开发者自己生成
                signature1: CryptoJS.MD5(random1 + key).toString(), // 将(random1 + 签名Key)的字符串拼接后做MD5运算得出
                random2: random2, // 随机字符串，长度不限，由开发者自己生成
                signature2: CryptoJS.MD5(random2 + key).toString(), // 将(random2 + 签名Key)的字符串拼接后做MD5运算得出
                skillID: '819e219a-f735-a5f5-9747-497557de0d28', // 必填字段，技能ID。填写本字段后SDK会在初始化阶段调用BotApp.requireShipping(小度有屏音箱环境)方法。
            });
            if (!botApp.isInApp()) {
                botApp.listen();
                botApp.speak('欢迎使用');
                botApp.onHandleUnknowUtterance(function (err, data) {
                    console.log(data);
                    // 当用户对话：小度小度，哈密瓜
                    // 打印结果如下：
                    console.log(data);
                });
            }
        } catch (error) {
            console.error(error);
        }
    },
    destroyed() {
        lifecycle.removeEventListener(
            'statechange',
            this.onLifeCycleStateChange
        );
    },
    methods: {
        ...mapActions('app', ['setPageHiddenStatus']),

        onLifeCycleStateChange(event) {
            if (event.newState === 'terminated') {
                //缓存状态
            } else if (event.newState === 'frozen') {
                // 结束通话
            } else if (
                event.newState === 'passive' &&
                event.oldState === 'hidden'
            ) {
                console.log('page is visible');
                this.setPageHiddenStatus(false);
            } else if (
                event.newState === 'hidden' &&
                event.oldState === 'passive'
            ) {
                console.log('page is hidden');
                this.setPageHiddenStatus(true);
            }
        },
    },
};
</script>

<style>
body {
    overflow: hidden;
    overscroll-behavior: none;
    touch-action: manipulation;
    -webkit-touch-callout: none;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
    padding-top: constant(safe-area-inset-top); /*兼容 IOS<11.2*/
    padding-top: env(safe-area-inset-top); /*兼容 IOS>11.2*/
    /** 去除左右偏移，避免微信浏览器横屏时宽度变窄 */
    /* padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right); */
    max-width: 100vw;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
    max-width: 100vw;
    overflow: hidden;
}
p {
    padding: 0;
    margin: 0;
}
img {
    pointer-events: none;
    -webkit-pointer-events: none;
    -ms-pointer-events: none;
    -moz-pointer-events: none;
}

img {
    -webkit-touch-callout: none;
    pointer-events: none;
}

div {
    -webkit-user-select: none;
}

div {
    -webkit-touch-callout: none;
}
</style>

