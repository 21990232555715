export const types = {
    SET_PHONE: 'app/SET_PHONE',
    SET_USER_INFO: 'app/SET_USER_INFO',
    SET_ACTION_STATUS: 'app/SET_ACTION_STATUS',
    SET_BUTTON_STATUS: 'app/SET_BUTTON_STATUS',
    SET_SYSTEM_CONFIG: 'app/SET_SYSTEM_CONFIG',
    SET_PAGE_HIDDEN_STATUS: 'app/SET_PAGE_HIDDEN_STATUS',
};
export default {
    namespaced: true,
    state: {
        phone: '',
        userInfo: {},
        actionStatus: '', // ready | idle | start | stop | listening | speaking
        buttonStatus: '', // touchstart | touchend
        sysConfig: {
            aichat_image_role: '', //图像角色
            aichat_tts_role: '', //语音角色,
            aichat_system_message: '', //系统提示词
            aichat_background_music_enable_flag: 0, //背景音乐开关
            aichat_channel_type: 0,
            aichat_company_prompt: '', //公司提示词
            company_simple_name: '帝派',
            aichat_start_type: 0,
        },
        buttonType: 'longtouch', // longtouch | switch
        pageHidden: false, // page is hidden
    },
    mutations: {
        [types.SET_PHONE](state, phone) {
            state.phone = phone;
        },
        [types.SET_USER_INFO](state, data) {
            state.userInfo = { ...data };
        },
        [types.SET_ACTION_STATUS](state, status) {
            state.actionStatus = status;
        },
        [types.SET_BUTTON_STATUS](state, status) {
            state.buttonStatus = status;
        },
        [types.SET_SYSTEM_CONFIG](state, data) {
            state.sysConfig = { ...data };
            console.log(state.sysConfig);
            state.buttonType =
                data.aichat_start_type === 1 ? 'switch' : 'longtouch';
        },
        [types.SET_PAGE_HIDDEN_STATUS](state, status) {
            state.pageHidden = status;
        },
    },
    actions: {
        setPhone({ commit }, phone) {
            commit(types.SET_PHONE, phone);
        },
        setUserInfo({ commit }, data) {
            commit(types.SET_USER_INFO, data);
        },
        setActionStatus({ commit }, status) {
            commit(types.SET_ACTION_STATUS, status);
        },
        setButtonStatus({ commit }, status) {
            commit(types.SET_BUTTON_STATUS, status);
        },
        setSysConfig({ commit }, data) {
            commit(types.SET_SYSTEM_CONFIG, data);
        },
        setPageHiddenStatus({ commit }, status) {
            commit(types.SET_PAGE_HIDDEN_STATUS, status);
        },
    },
};

