<template>
    <div class="sound-effect-block"> </div>
</template>

<script>
import { Howl, Howler } from 'howler';
import { mapState } from 'vuex';
export default {
    props: {},
    data() {
        return {
            buttonSound: require('../../assets/audio/button.mp3'),
            sound: null,
        };
    },
    computed: {
        ...mapState('app', ['buttonStatus']),
    },
    watch: {
        buttonStatus(newVal) {
            if (['touchstart'].includes(newVal) && this.sound) {
                this.play('buttonDown');
            } else if (['touchend'].includes(newVal) && this.sound) {
                this.play('buttonUp');
            }
        },
    },
    mounted() {
        this.load();
    },
    destroyed() {},
    methods: {
        load() {
            var sound = new Howl({
                src: [this.buttonSound],
                sprite: {
                    buttonDown: [0, 300],
                    buttonUp: [0, 300],
                },
            });

            // Change global volume.
            Howler.volume(0.5);

            this.sound = sound;
        },

        play(type) {
            if (!this.sound || !type) {
                return;
            }
            this.sound.play(type);
        },
    },
};
</script>

<style scoped lang="less">
.sound-effect-block {
    position: absolute;
    width: 0;
    height: 0;
}
</style>

